import React, { useCallback, useState } from "react";
import {
  Button,
  Modal,
  TextContainer,
  FormLayout,
  TextField,
} from "@shopify/polaris";

function ModalForm() {
  return (
    <FormLayout>
      <TextField
        label="To:"
        type="email"
        onChange={() => {}}
        autoComplete="email"
      />
      <TextField label="Subject" onChange={() => {}} autoComplete="off" />
      <TextField
        type="email"
        label="Message"
        onChange={() => {}}
        autoComplete="off"
        multiline={10}
      />
    </FormLayout>
  );
}

export default function ComposeModal() {
  const [active, setActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = (
    <Button size="large" className="navNewEmail" onClick={handleChange}>
      Compose
    </Button>
  );

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Compose New Message"
        primaryAction={{
          content: "Send",
          onAction: handleChange,
        }}
        secondaryActions={[
          {
            content: "Save",
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <ModalForm />
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}
