import React from 'react';
import {Banner} from '@shopify/polaris';

export default class App extends React.Component {
  render() {
    return (
        <span id="info-banner">
      <Banner
      
  title="Info About App"
  action={{content: 'Back to Github', url: 'https://fullmetalfenix.github.io/'}}
  status="info"
  onDismiss={() => {
    document.getElementById('info-banner').classList.add('bye-banner');
  }}
>
  <p>Some Interactive Features:</p>
  <ul>
      <li>
          Dark / Light Theme changes
      </li>
      <li>
          Compose Mail Modal
      </li>
      <li>
          Sorted Mail Buckets (Tabs)
      </li>
  </ul>
</Banner>
</span>
    );
  }
}