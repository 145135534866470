import React from 'react';
import {useIndexResourceState, Card, IndexTable, TextStyle} from '@shopify/polaris';

export default function SimpleIndexTableExample(props) {

  const emails = props.emails;  
  const resourceName = {
    singular: 'Email',
    plural: 'Emails',
  };

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(emails);

  const rowMarkup = emails.map(
    ({id, from, message, date}, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <TextStyle  variation="strong">{from}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>{message}</IndexTable.Cell>
        <IndexTable.Cell>{date}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card>
      <IndexTable
        resourceName={resourceName}
        itemCount={emails.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={[
          {title: 'From'},
          {title: 'Subject'},
          {title: 'Date'},
        ]}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  );
}
