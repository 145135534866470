import React, { useCallback, useState } from "react";
import { Card, Tabs, Page, Layout } from "@shopify/polaris";
import TopBodyBar from "./TopBodyBar";
import EmailTable from "./EmailTable";
import InfoBanner from "./InfoBanner";

const emails = [
    {
      id: 1,
      from: "Frodo",
      message: "Re: The One Ring",
      date: "Oct 4",
      type: "social",
    },
    {
      id: 2,
      from: "Smegol",
      message: "The One Ring",
      date: "Oct 4",
      type: "social",
    },
    {
      id: 3,
      from: "Samwise",
      message: "100 & 1 Potato Recipies",
      date: "Oct 4",
      type: "social",
    },
    {
      id: 4,
      from: "Merry",
      message: "New breakfast descovered by Hobbit Scientists!",
      date: "Oct 4",
      type: "promotion",
    },
    {
      id: 5,
      from: "Troll",
      message: "Sunlight Bad",
      date: "Oct 3",
      type: "social",
    },
    {
      id: 6,
      from: "Gandalf",
      message: "What shoes go with white robes?",
      date: "Oct 3",
      type: "social",
    },
    {
      id: 7,
      from: "Rivendell Post",
      message: "No bake lembas bread recipies",
      date: "Oct 3",
      type: "promotion",
    },
    {
      id: 8,
      from: "Tom Bombadil",
      message: "Hello, anyone ???",
      date: "Oct 2",
      type: "social",
    },
  ];

function SectionTabs() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "all-emails",
      content: "All Emails",
      bodyContent: <EmailTable emails={emails} />,
      accessibilityLabel: "All Emails",
      panelID: "all-email-content-1",
    },
    {
      id: "social-emails",
      content: "Social",
      bodyContent: (
        <EmailTable emails={emails.filter((item) => item.type === "social")} />
      ),
      accessibilityLabel: "Social Emails",
      panelID: "accepts-marketing-content-1",
    },
    {
      id: "promotion-emails",
      content: "Promotional",
      bodyContent: (
        <EmailTable
          emails={emails.filter((item) => item.type === "promotion")}
        />
      ),
      accessibilityLabel: "Promotional Emails",
      panelID: "repeat-customers-content-1",
    },
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <Card.Section title={tabs[selected].accessibilityLabel}>
          <p>{tabs[selected].bodyContent}</p>
        </Card.Section>
      </Tabs>
    </Card>
  );
}

export default function BodySection() {
  return (
    <Page className="" fullWidth>
      <InfoBanner />
      <Layout>
        <Layout.Section>
          <TopBodyBar />
        </Layout.Section>
        <Layout.Section>
          <SectionTabs />
        </Layout.Section>
      </Layout>

    </Page>
  );
}
