import React, { useCallback, useState } from "react";
import {
  AppProvider,
  ActionList,
  Icon,
  TopBar,
  RadioButton,
  Stack,
} from "@shopify/polaris";
import {
  ArrowLeftMinor,
  QuestionMarkMajor,
  CircleDotsMajor,
  PaintBrushMajor,
  GlobeMajor,
  LegalMajor,
  SettingsMajor,
  AutomationMajor,
  AccessibilityMajor,
  ChatMajor,
  ClockMajor,
  DomainsMajor,
  FeaturedCollectionMajor,
} from "@shopify/polaris-icons";
import TopMenuActionList from "./ActionList";

export default function AppTopBar(props) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const themeIrl = props.theme;
  console.log(themeIrl);
  const imgUrl = props.theme
    ? "https://b7d9f7a1.polaris-6z7.pages.dev/logo-light-2.JPG"
    : "https://b7d9f7a1.polaris-6z7.pages.dev/logo-2.JPG";

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const toggleIsSecondaryMenuOpen = useCallback(
    () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    []
  );

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue("");
  }, []);

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);
  }, []);

  const handleNavigationToggle = useCallback(() => {
    console.log("toggle navigation visibility");
  }, []);

  const [radioValue, setRadioValue] = useState("disabled");

  const handleRadioChange = useCallback((_checked, newValue) => {
    props.setTheme(!props.theme);
    setRadioValue(newValue);
  });

  function GearMenu() {
    return (
      <Stack vertical>
        <RadioButton
          label="Light Mode"
          helpText="Good for High light enviornments"
          checked={radioValue === "disabled"}
          id="disabled"
          name="accounts"
          onChange={handleRadioChange}
        />
        <RadioButton
          label="Dark Mode"
          helpText="Good for low light enviornments."
          id="optional"
          name="accounts"
          checked={radioValue === "optional"}
          onChange={handleRadioChange}
        />
      </Stack>
    );
  }

  function bbb() {
    return (
      <div>
        <p>Hello World</p>
      </div>
    );
  }
  const theme = {
    logo: {
      width: 124,
      topBarSource: imgUrl,
      url: "https://google.com",
      accessibilityLabel: "Polaris / Gmail",
    },
  };

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [{ content: "Account Info", icon: ArrowLeftMinor }],
        },
        {
          items: [{ content: "More account info or whatever  " }],
        },
      ]}
      initials="U"
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  function AppsMegaMenu(props) {
    return (
      <div class="app-icon-container">
        <Icon source={props.icon} />
        <p className="mega-menu-description">{props.description}</p>
      </div>
    );
  }

  function IconMenu() {
    return (
      <div className="second-menu">
        <TopMenuActionList
          icon={QuestionMarkMajor}
          dropdownArray={[
            { content: "Help" },
            { content: "Training" },
            { content: "Updates" },
          ]}
        />
        <TopMenuActionList
          icon={SettingsMajor}
          dropdownArray={[{ content: <GearMenu /> }]}
        />
        <TopMenuActionList
          icon={CircleDotsMajor}
          dropdownArray={[
            {
              content: (
                <AppsMegaMenu
                  description={"Accessibility"}
                  icon={AccessibilityMajor}
                />
              ),
            },
            { content: <AppsMegaMenu description={"Chat"} icon={ChatMajor} /> },
            {
              content: <AppsMegaMenu description={"Clock"} icon={ClockMajor} />,
            },
            {
              content: (
                <AppsMegaMenu description={"Domains"} icon={DomainsMajor} />
              ),
            },
            {
              content: (
                <AppsMegaMenu
                  description={"Collections"}
                  icon={FeaturedCollectionMajor}
                />
              ),
            },
            {
              content: (
                <AppsMegaMenu description={"Global"} icon={GlobeMajor} />
              ),
            },
            {
              content: <AppsMegaMenu description={"Legal"} icon={LegalMajor} />,
            },
            {
              content: (
                <AppsMegaMenu
                  description={"Automation"}
                  icon={AutomationMajor}
                />
              ),
            },
            {
              content: (
                <AppsMegaMenu description={"Paint"} icon={PaintBrushMajor} />
              ),
            },
          ]}
        />
      </div>
    );
  }

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Change Account Info", icon: QuestionMarkMajor },
        { content: "Go To My Account" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder="Search Mail"
      showFocusBorder
    />
  );

  return (
    <div>
      <AppProvider theme={theme}>
        <TopBar
          showNavigationToggle
          userMenu={userMenuMarkup}
          secondaryMenu={<IconMenu />}
          IconMenu={IconMenu}
          searchResultsVisible={isSearchActive}
          searchField={searchFieldMarkup}
          searchResults={searchResultsMarkup}
          onSearchResultsDismiss={handleSearchResultsDismiss}
          onNavigationToggle={handleNavigationToggle}
        />
      </AppProvider>
    </div>
  );
}
