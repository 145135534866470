import React, { useCallback, useState } from "react";
import { ActionList, Button, Popover } from "@shopify/polaris";

export default function TopMenuActionList(props) {
  const [active, setActive] = useState(true);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button icon={props.icon} onClick={toggleActive} disclosure></Button>
  );

  return (
    <div className="second-menu-button">
      <Popover active={!active} activator={activator} onClose={toggleActive}>
        <ActionList items={props.dropdownArray} />
      </Popover>
    </div>
  );
}
