import './App.css';
import '@shopify/polaris/build/esm/styles.css';
import React, {useState} from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider,Frame, ThemeProvider, Layout, Page, ActionList} from '@shopify/polaris';
import TopBar from './components/TopBar';
//import ActionList from './components/ActionList';
//import {ArrowLeftMinor, QuestionMarkMajor, CircleDotsMajor, SettingsMajor} from '@shopify/polaris-icons';
//import TopMenuActionList from './components/ActionList';
import SideNavigation from './components/SideNavigation';
import TopBodyBar from './components/TopBodyBar'
import BodySection from './components/BodySection'



const theme = {
  logo: {
    width: 124,
    topBarSource:
      './logo_gmail.png',
    url: 'https://google.com',
    accessibilityLabel: 'Polaris / Gmail',
  },
};



function App() {

  const [theme, setTheme] = useState(true);
  var themeColor = (theme)? 'light':'dark'; 
  return (

  <div id={`body-theme-${themeColor}`} className="App">
  <AppProvider 
  theme={theme}
  i18n={enTranslations}>
  <ThemeProvider theme={{colorScheme: themeColor}}>
  <Frame
        navigation={<SideNavigation />}
        topBar={<TopBar setTheme={setTheme} theme={theme}/>}
        >
  <BodySection/>
        
  </Frame>



  </ThemeProvider>
  </AppProvider>    
  </div>
  );
}

export default App;
