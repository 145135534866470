import React from "react";
import { Navigation } from "@shopify/polaris";
import {
  OrdersMajor,
  SendMajor,
  InventoryMajor,
  DraftOrdersMajor
} from "@shopify/polaris-icons";
import ComposeModal from './ComposeModal';


export default function SideNavigation() {

    return (
      <Navigation location="/">
        <ComposeModal />
        <Navigation.Section
          items={[
            {
              url: "/path/to/place",
              label: "Inbox",
              icon: OrdersMajor
            },
            {
              url: "/path/to/place",
              label: "Sent",
              icon: SendMajor
            },
            {
              url: "/path/to/place",
              label: "Drafts",
              icon: DraftOrdersMajor
            }
          ]}
        />
        <Navigation.Section
          items={[
            {
              url: "/path/to/place",
              label: "Back To The Shire",
              icon: InventoryMajor
            }
          ]}
          separator
        />
      </Navigation>
    );
}
