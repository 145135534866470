import React, { useCallback, useState } from "react";
import { Icon, ActionList, Button, Popover } from "@shopify/polaris";
import {
  ListMajor,
  MobileVerticalDotsMajor,
  RefreshMajor,
} from "@shopify/polaris-icons";

export default function TopBodyBar() {
  const [active, setActive] = useState(true);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleImportedAction = useCallback(
    () => console.log("Imported action"),
    []
  );

  const handleExportedAction = useCallback(
    () => console.log("Exported action"),
    []
  );

  const activator = (
    <Button onClick={toggleActive} disclosure>
      <Icon source={ListMajor} />
    </Button>
  );

  return (
    <div
      style={{
        height: "250px",
        marginTop: "4px",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        maxHeight: "30px",
      }}
    >
      <div
        style={{
          marginTop: "4px",
          display: "flex",
          flexDirection: "row",
          minWidth: "200px",
          justifyContent: "space-between",
        }}
      >
        <Popover active={!active} activator={activator} onClose={toggleActive}>
          <ActionList
            items={[
              {
                content: "Mark as Read",
                onAction: handleImportedAction,
              },
              {
                content: "Delete Selected",
                onAction: handleImportedAction,
              },
              {
                content: "Save Selected",
                onAction: handleExportedAction,
              },
            ]}
          />
        </Popover>
        <Button>
          <Icon source={RefreshMajor} />
        </Button>
        <Button>
          <Icon source={MobileVerticalDotsMajor} />
        </Button>
      </div>
    </div>
  );
}
